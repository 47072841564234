import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import ApplicationLayout from '../../components/ApplicationLayout'
import Loading from '../../components/Loading'
import MeetingMultiViewHeaders from './components/Headers'
import TeamLeaderSelectorModal from './components/TeamLeaderSelectorModal'
import MeetingCompleteModal from './components/MeetingCompleteModal'
import { useState, useEffect } from 'react'
import '../MeetingView/styles.css'
import '../MeetingView/responsive.css'
import ErrorModal from '../../components/ErrorModal'
import { useTranslation } from 'react-i18next'
import BGUScoreCard from './components/BGUScoreCard'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import BGUTopicAgeTableModal from './components/BGUScoreCard/component/BGUTopicAgeTableModal'
import BGUOpenCloseActionTableModal from './components/BGUScoreCard/component/BGUOpenCloseActionTableModal'
import BGUActivityDuesTableModal from './components/BGUScoreCard/component/BGUActivityDuesTableModal'
import { getConsolidatedConfigSettings, hasRole } from '../../utils/auth'
import SelectLeaderModal from '../../components/SelectLeaderModal'
import { meetingMultiViewSubRoutes, routes } from '../../utils/routes'
import { meetingViewModesEnum } from '../../utils/enumValues'
import { useNavbarContext } from '../../utils/navBarContext'
import { checkForPendingUW } from '../../common/checkForPendingUwOnLogin'
import JoyrideComponent from './components/JoyRide'
import Toast from '../../common/toast'
import { checkForBrowser } from '../../common/checkForBrowser'
import MeetingTranscription from '../../components/MeetingTranscription'
import { useMeetingRecording } from '../../utils/meetingRecordingContext/meetingRecordingContext'

const MeetingMultiViewPage = () => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const { state } = useMeetingRecording()

  const [refreshIndex, setRefreshIndex] = useState(0)
  const [isTeamLeaderSelectorModalOpen, setIsTeamLeaderSelectorModalOpen] = useState(false)
  const [eIdsOfSelectedTls, setEIdsOfSelectedTls] = useState([])
  const [isCompleteResolutionModalOpen, setIsCompleteResolutionModalOpen] = useState(false)

  const [refreshBGUScorecard, setRefreshBGUScorecard] = useState(false)
  const [BGUScoreCardData, setBGUScoreCardData] = useState(null)
  const [reload, setReload] = React.useState(0)

  // const { data: teamLeadersData, isLoading } = useAreaLeaderTeamLeaders()
  const [teamLeadersData, setTeamLeadersData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [errorModalMessage, setErrorModalMessage] = React.useState(null)
  const [isSelectLeaderModalOpen, setIsSelectLeaderModalOpen] = React.useState(false)
  const [selectedLeaderToLoadMH, setSelectedLeaderToLoadMH] = React.useState(null)
  const [namesDisplayStr, setNamesDisplayStr] = React.useState('')
  const [reloadMeetingHub, setReloadMeetingHub] = React.useState(false)
  const [teamLeadersForMhReload, setTeamLeadersForMhReload] = React.useState([])
  const [isInitialLoadMeeting, setIsInitialLoadMeeting] = React.useState(true)
  const [juniorTlName, setJuniorTlName] = React.useState('')
  const allowCxoUsersSeeL3Objs = getConsolidatedConfigSettings('allow_cxo_users_see_l3_objs')
  const [eIdsToLoadBGU, setEIdsToLoadBGU] = React.useState([])
  const [isScoreCardCollapsed, setIsScoreCardCollapsed] = React.useState(
    !state?.hasSummarizationStarted,
  )
  const [selectedViewMode, setSelectedViewMode] = React.useState(null)
  const { isNavbarCollapsed, handleNavbarStateChange } = useNavbarContext()
  const meetingHubContainerRef = React.useRef(null)
  const [childComponentLoadingState, setchildComponentLoadingState] = React.useState([])
  const [runJoyRide, setRunJoyRide] = React.useState(false)
  const [meetingTopicExist, setMeetingTopicExist] = React.useState(false)
  const [mhTutorialOff, setMhTutorialOff] = React.useState(false)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const isInitialLoad = queryParams.get('initialLoad')
  const [isCheckForPendingUwComplete, setIsCheckForPendingUwComplete] = React.useState(false)
  const { pathname } = location

  const configSettings = JSON.parse(localStorage.getItem('cfg'))
  const meetingHubViewMode = configSettings?.meeting_hub_view_mode

  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true

  React.useEffect(() => {
    // if isInitialLoad is true, then we need to check for pending UW
    // This happens when the user logs in for the first time
    console.log('isInitialLoad', isInitialLoad)
    if (isInitialLoad) {
      checkForBrowser(t).then(() => {
        checkForPendingUW(t).then((result) => {
          setIsCheckForPendingUwComplete(result)
        })
      })
    } else {
      setIsCheckForPendingUwComplete(true)
    }
  }, [])

  useEffect(() => {
    setIsScoreCardCollapsed(!state?.hasSummarizationStarted)
  }, [state?.hasSummarizationStarted])

  React.useEffect(() => {
    ;(async () => {
      try {
        let response = await getHttpRequest('/get_employees_user_preferences')
        setMhTutorialOff(response.mhTutorialOff)
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    })()
  }, [mhTutorialOff])

  // useEffect(() => {
  //   setIsScoreCardCollapsed(!(pathname.endsWith(meetingMultiViewSubRoutes.meetingTopics) || pathname.endsWith(routes.meetingMultiView)));
  // }, [pathname]);

  useEffect(() => {
    if (meetingHubViewMode !== null && meetingHubViewMode !== undefined) {
      setSelectedViewMode(meetingHubViewMode)
    }

    // by default area/cxo should see 'Me'
    else if (hasRole('area') || hasRole('cxo')) {
      setSelectedViewMode(meetingViewModesEnum.ACTION_VIEW)
    } else {
      setSelectedViewMode(meetingViewModesEnum.PEOPLE_VIEW)
    }
  }, [])

  const toggleScoreCardCollapse = () => {
    setIsScoreCardCollapsed(!isScoreCardCollapsed)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
  }

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const determineDivWidthPercentage = () => {
    if (meetingHubContainerRef.current) {
      const containerWidth = meetingHubContainerRef.current.clientWidth

      if (containerWidth >= 1600) {
        if (isNavbarCollapsed) {
          return '96%'
        } else {
          return '88%'
        }
      } else if (isNavbarCollapsed) {
        return '95%'
      } else {
        if (canUseMeetingSummarization) {
          return '83%'
        }
        return '85%'
      }
    }
  }

  // React.useEffect(() => {
  //   debugger
  //   getReportingLeaders()

  // }, [])

  React.useEffect(() => {
    getReportingLeaders()
  }, [selectedLeaderToLoadMH])

  React.useEffect(() => {
    if (eIdsOfSelectedTls && teamLeadersData?.employees) {
      if (eIdsOfSelectedTls.length === 0 || reloadMeetingHub) {
        let newIds = []
        for (let tl of teamLeadersData?.employees ?? []) {
          newIds.push(tl.eId)
        }
        setEIdsOfSelectedTls(newIds)

        setReloadMeetingHub(false)
      }
    }
  }, [teamLeadersData?.employees])

  const refreshBGUScorecardFn = () => {
    setRefreshBGUScorecard((refreshBGUScorecard) => !refreshBGUScorecard)
  }

  const loadAgain = () => {
    setReload(reload + 1)
  }

  function getBGUScoreCard() {
    let url = ''
    url = eIdsToLoadBGU.length > 0 ? url + '/' + eIdsToLoadBGU.join(',') : url

    let evaluatedViewMode = selectedViewMode

    let params = {
      viewMode: evaluatedViewMode,
    }

    getHttpRequest(`/bgu_scorecard_response${url}`, { params: { ...params } }).then((response) => {
      const data = response
      if (data) {
        setBGUScoreCardData(data)
      } else {
      }
    })
  }

  const getLoadingStateFromChildComponent = (componentName, isLoaded) => {
    setchildComponentLoadingState({ ...childComponentLoadingState, [componentName]: isLoaded })
  }

  useEffect(() => {
    if (selectedViewMode === null) {
      return
    }
    // getBGUScoreCard()
  }, [refreshBGUScorecard, eIdsToLoadBGU, selectedViewMode])

  useEffect(() => {
    let namesDisplayString = t('Common:filter.all')
    if (teamLeadersData?.employees?.length !== eIdsOfSelectedTls.length) {
      let namesDisplayArray = eIdsOfSelectedTls
        ?.map((eid, indexEid) => teamLeadersData?.employees?.find((e) => e.eId == eid).name)
        .sort((a, b) => b.name - a.name)
      namesDisplayString = namesDisplayArray.join(', ')
    }
    setNamesDisplayStr(namesDisplayString)
  }, [eIdsOfSelectedTls])

  useEffect(() => {
    if (BGUScoreCardData != null) {
      getLoadingStateFromChildComponent('BGUScoreCard', true)
    }
  }, [BGUScoreCardData])

  useEffect(() => {
    if (
      childComponentLoadingState.MeetingTopic == true &&
      // childComponentLoadingState.BGUScoreCard &&
      !mhTutorialOff
    ) {
      setTimeout(() => {
        setRunJoyRide(true)
        console.log('timeout')
        console.log(meetingTopicExist)
      }, 2000)
    }
  }, [childComponentLoadingState, mhTutorialOff])

  if (isLoading) {
    return <Loading />
  }

  const loading = (isLoading) => {
    const tradeOffOverlay = document.querySelector('.meeting-view-overlay')
    const tradeOffSpinner = tradeOffOverlay.querySelector('.meeting-view-spinner')

    if (isLoading) {
      tradeOffOverlay.classList.remove('hidden')
      tradeOffSpinner.classList.remove('hidden')
    } else {
      tradeOffOverlay.classList.add('hidden')
      tradeOffSpinner.classList.add('hidden')
    }
  }
  const overlayClickHandler = (e) => {
    const tradeOffOverlay = document.querySelector('.meeting-view-overlay')

    if (e.target === tradeOffOverlay) {
      showMeetingViewModal(false)
    }
  }

  const showMeetingViewModal = (shouldShow) => {
    const tradeOffOverlay = document.querySelector('.meeting-view-overlay')
    const tradeOffModal = tradeOffOverlay.querySelector('.meeting-view-modal')

    if (shouldShow) {
      tradeOffOverlay.classList.remove('hidden')
      tradeOffModal.classList.remove('hidden')
    } else {
      tradeOffOverlay.classList.add('hidden')
      tradeOffModal.classList.add('hidden')
      tradeOffModal.innerHTML = ''
    }
  }

  const updateRefreshIndex = () => {
    setRefreshIndex((refreshIndex) => refreshIndex + 1)
  }

  const loadJuniorTLMeetingHub = (selectedEmp) => {
    setSelectedLeaderToLoadMH(selectedEmp.eId)
    setJuniorTlName(selectedEmp.name)
    setReloadMeetingHub(true)
  }

  async function getReportingLeaders() {
    setIsLoading(true)
    let result = null
    if (selectedLeaderToLoadMH) {
      result = await getHttpRequest(
        '/team_leaders_for_area_leader' + '/' + selectedLeaderToLoadMH,
        {
          params: {
            includeSelf: true,
          },
        },
      )
    } else {
      result = await getHttpRequest('/team_leaders_for_area_leader', {
        params: {
          includeSelf: true,
          notExcludeCos: true,
        },
      })
    }
    let employees = result ?? []

    setTeamLeadersData(employees)
    setIsLoading(false)
    if (isInitialLoadMeeting) {
      setTeamLeadersForMhReload(employees)
      setIsInitialLoadMeeting(false)
    }
  }

  const handleMineButtonClick = () => {
    setEIdsOfSelectedTls([])
    setSelectedLeaderToLoadMH(null)
    setReloadMeetingHub(true)
    setJuniorTlName('')
  }

  const isInDecisionLogTab =
    location.pathname.includes('LeadershipDecisions') || location.pathname.includes('DecisionLog')

  // Check if the current URL is in Summary of Objectives "/TeamMeetingView/LeadershipDecisions"
  const isInSummaryOfObjectiveTab = location.pathname.includes('MilestonesOverview')

  const isInKpiReportByLeader = location.pathname.includes('KpiReportByLeader')

  const getIfMeetingTopicExists = (meetingTopic) => {
    setMeetingTopicExist(meetingTopic)
  }

  return (
    <ApplicationLayout>
      <div
        ref={meetingHubContainerRef}
        className="meeting-hub-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: determineDivWidthPercentage(),
          position: 'absolute',
        }}
      >
        <ErrorModal
          isOpen={isErrorModalOpen}
          handleClose={closeErrorModal}
          errorMessage={errorModalMessage}
        />

        {isTeamLeaderSelectorModalOpen === true ? (
          <TeamLeaderSelectorModal
            teamLeadersData={teamLeadersData}
            eIdsOfSelectedTls={eIdsOfSelectedTls}
            setEIdsOfSelectedTls={setEIdsOfSelectedTls}
            closeModal={() => setIsTeamLeaderSelectorModalOpen(false)}
            updateRefreshIndex={updateRefreshIndex}
          />
        ) : null}

        {isCompleteResolutionModalOpen === true ? (
          <MeetingCompleteModal
            closeModal={setIsCompleteResolutionModalOpen}
            updateRefreshIndex={updateRefreshIndex}
            openErrorModal={openErrorModal}
          />
        ) : null}

        {/* {isActivityModalOpen === true && selectedBar !== null && activitiesDuesCategory!==null &&
        <BGUActivityDuesTableModal
          data={selectedBar}
          isModalOpen={isActivityModalOpen}
          closeModal={closeTestModal}
          eIdsOfSelectedTls={eIdsOfSelectedTls}
          loadAgain={loadAgain}
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          activitiesDuesCategory={activitiesDuesCategory}
        />
      } */}

        {isSelectLeaderModalOpen === true && (
          <SelectLeaderModal
            employees={teamLeadersForMhReload}
            isModalOpen={isSelectLeaderModalOpen}
            closeModal={() => setIsSelectLeaderModalOpen(false)}
            handleSubmit={loadJuniorTLMeetingHub}
          />
        )}

        <div className="meeting-view-overlay hidden" onClick={(e) => overlayClickHandler(e)}>
          <div className="meeting-view-modal hidden"></div>
          <div className="meeting-view-spinner hidden"></div>
        </div>

        <div className="meeting-view" style={{ paddingTop: '3.5rem' }}>
          <div className="page_title">{t('meetingHubPageTitle')}</div>

          {/* <div
            className="modal-dual-btn"
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0 3rem 0.5rem 0.5rem',
              justifyContent: 'flex-start',
            }}
          > */}
          {/* Commenting the filters until we finalize the new strategy */}
          {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', width: '53%' }}>
            <div
              class="fitted-button blue"
              style={{
                margin: '0 1rem 0 0',
                columnGap: "0.5rem"
              }}
              onClick={() => {
                setIsTeamLeaderSelectorModalOpen(true)
              }}
            >
              <span class="material-symbols-outlined">filter_list</span>

              <span> {t("Common:commonButtons.teamLeaders")}</span>

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', color: '#737F8F' }}>
              <span>{namesDisplayStr}</span>
            </div>
          </div> */}
          {/* TODO Swetha : Commenting out temporarily as it confuses the users */}
          {/* {((hasRole('cxo') && !allowCxoUsersSeeL3Objs) || (hasRole('cos') && localStorage.getItem('ilType') == 1 )) && (
            <div style={{ display: 'flex', flexDirection: 'column', columnGap: '1rem' }}>
              <div style={{ margin: '0 0 0.5rem 0rem' }}>{t('showTopicsFor')}</div>
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                <div style={{
                  cursor: 'pointer',
                  display: 'flex',
                  width: 'fitContent',
                  height: 'fitContent',
                  borderRadius: '4px',
                  color: 'white',
                  padding: '0.3rem 1rem 0.3rem 1rem', backgroundColor: !selectedLeaderToLoadMH ? '#4472c4':'#a6a6a6'
                }} onClick={handleMineButtonClick}>{t("Common:commonButtons.me")}</div>

                <div style={{
                  cursor: 'pointer',
                  display: 'flex',
                  width: 'fitContent',
                  height: 'fitContent',
                  borderRadius: '4px',
                  color: 'white',
                  padding: '0.3rem 1rem 0.3rem 1rem', backgroundColor: selectedLeaderToLoadMH ? '#4472c4':'#a6a6a6'
                }}
                  onClick={() => { setIsSelectLeaderModalOpen(true) }}
                >{t("Common:commonButtons.myTeam")}</div>

                {juniorTlName && (
                  <div style={{ display: 'flex', flexDirection: 'row', color: '#737F8F' }}>{t('showingJuniorTlMhMsg')}{juniorTlName}</div>)}
              </div>
            </div>
          )} */}
          {/* </div> */}

          <MeetingMultiViewHeaders hasF2WCapacity={false} />
          <div className="meetingview-view-body">
            <div
              className={`meeting-view-container ${
                isScoreCardCollapsed ? 'expanded' : 'collapsed'
              } ${canUseMeetingSummarization ? '' : 'no-meeting-summarization'}`}
            >
              <Outlet
                context={{
                  isLoading,
                  loading,
                  showMeetingViewModal,
                  setRefreshIndex,
                  updateRefreshIndex,
                  refreshBGUScorecardFn,
                  loadAgain,
                  reload,
                  selectedLeaderToLoadMH,
                  teamLeadersData,
                  setEIdsToLoadBGU,
                  isTeamMeetingView: false,
                  setSelectedViewMode,
                  selectedViewMode,
                  getLoadingStateFromChildComponent,
                  getIfMeetingTopicExists,
                  isScoreCardCollapsed,
                }}
              />
              {/* <JoyrideComponent run={runJoyRide}/> */}
            </div>
            {canUseMeetingSummarization && !hasRole('ic') && (
              <div className={`scorecard-menu ${isScoreCardCollapsed ? ' collapsed' : 'expanded'}`}>
                {isScoreCardCollapsed ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div class="fitted-button" onClick={toggleScoreCardCollapse}>
                      <span class="material-symbols-outlined">right_panel_open</span>
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div class="fitted-button" onClick={toggleScoreCardCollapse}>
                        <span class="material-symbols-outlined" id="meetingHubTutorial4">
                          chevron_right
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          paddingRight: '1rem',
                          paddingTop: '0.3rem',
                          paddingLeft: '0.5rem',
                          fontSize: '13px',
                          color: '#949494',
                        }}
                      >
                        {state.hasSummarizationStarted
                          ? t('transcript.summarizingMeetingInProgress')
                          : t('transcript.startSummarizingMeeting')}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        // paddingLeft:'1rem',
                        textAlign: 'left',
                        marginLeft: '1rem',
                        marginTop: '1rem',
                        marginBottom: '0.3rem',
                        color: '#5780ca',
                      }}
                    >
                      {t('transcript.title')}
                    </div>
                    <div className="divider"></div>
                  </div>
                )}

                <div
                  class="bgu-container"
                  style={{
                    visibility: isScoreCardCollapsed ? 'hidden' : 'visible',
                    overflow: 'auto',
                  }}
                >
                  <MeetingTranscription />
                  {/* {BGUScoreCardData &&
              // <BGUScoreCard
              //   actionChartData={BGUScoreCardData}
              //   setIsActionModalOpen={setIsActionModalOpen}
              //   setIsActivityModalOpen={setIsActivityModalOpen}
              //   setIsTopicModalOpen={setIsTopicModalOpen}
              //   setSelectedBar={setSelectedBar}
              //   setIsOpen={setIsOpen}
              //   setActivitiesDuesCategory={setActivitiesDuesCategory}
              //   refreshBGUScorecardFn={refreshBGUScorecardFn}
              //   loadAgain={loadAgain}
              //   visibility={isScoreCardCollapsed ? 'hidden' : 'visible'}
              //   getLoadingStateFromChildComponent = {getLoadingStateFromChildComponent}

              // />
              // } */}
                  {/* <JoyrideComponent run={runJoyRide}/> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <JoyrideComponent run={runJoyRide} meetingTopicExist={meetingTopicExist} />
    </ApplicationLayout>
  )
}

export default MeetingMultiViewPage

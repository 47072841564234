import React, { useState } from 'react'

import { Handle, Position } from 'reactflow'
import './customNode.scss'
import ActivityIcon from '../../../assets/activity.svg'
import MessageIcon from '../../../assets/message.svg'
import ZoomIcon from '../../../assets/zoom.svg'
import ZoomOutIcon from '../../../assets/zoomOut.svg'
import ObjMapDropdownMenu from './objMapDropdownMenu'
import { getConsolidatedConfigSettings } from '../../../utils/auth'
import './styles.scss'

const handleStyle = { left: 10 }

function TopicNode({ data }) {
  // Default hideDropdown to false
  const hideDropdown = data.hideDropdown || false
  const getBackgroundColor = (topicType) => {
    const colors = {
      0: '#F6aAB6C',
      1: 'rgb(237, 82, 46)',
      2: 'blue',
      3: 'gray',
      4: '#9933FF',
    }
    return colors[topicType] || 'transparent'
  }
  return (
    <div className="nodrag text-updater-node">
      <div className={`custom-box-node topic-box`}>
        <ul className="node-text-list">
          <li className="single-node" style={{ marginBottom: '0.2rem' }}>
            <div
              className="topic-blocker"
              style={{ backgroundColor: getBackgroundColor(data.topicType) }}
              onClick={() => data.ShowCardTopicModal(data.umtId, data.topicType)}
            >
              {data.topicType === 1
                ? 'Blocker'
                : data.topicType === 2
                  ? 'Guidance'
                  : data.topicType === 3
                    ? 'Update'
                    : data.topicType === 4
                      ? 'admin'
                      : 'Topic'}
            </div>
            <p style={{ overflow: 'auto', height: '2rem' }}>{data.description}</p>
          </li>
        </ul>
        {!hideDropdown && (
          <div className="icons-list-node-box">
            <div
              style={{
                width: '100%',
                alignSelf: 'flex-end',
                gap: '0.313rem',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                margin: '0 0.1rem 0.5rem 0.1rem',
                padding: '0',
              }}
            >
              {data?.kpiName ? (
                <li className="fitted-button blue" style={{ height: '1rem' }}>
                  <div class=" tooltip">
                    <span class="material-symbols-outlined icon-size-sm">query_stats</span>
                    <span class="tooltiptext">{data?.kpiName}</span>
                  </div>
                </li>
              ) : (
                <div></div>
              )}
              <ObjMapDropdownMenu
                topicMode={true}
                ShowEditTopicModal={() => {
                  data.ShowEditTopicModal(data)
                }}
                ShowCardTopicModal={() => {
                  data.ShowCardTopicModal(data.umtId, data.topicType)
                }}
                ShowDeleteTopicModal={() => {
                  data.ShowDeleteTopic(data.umtId)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TopicNode
